import { defineStore } from "pinia";
import {
  listCourses,
  getEnrolledCourses,
  getPastEnrolledCourses,
  updateCourseParticipants,
} from "@/services/api/courses";
import { Course, CourseParticipant } from "@/interfaces/courses.types";

interface CourseState {
  courses: Course[];
  enrolledCourses: Course[];
  pastEnrolledCourses: Course[];
  loading: boolean;
}

export const useCourseStore = defineStore("course", {
  state: (): CourseState => ({
    courses: [],
    enrolledCourses: [],
    pastEnrolledCourses: [],
    loading: false,
  }),
  getters: {
    courseCount(state): number {
      return state.courses.length;
    },
    enrolledCourseCount(state): number {
      return state.enrolledCourses.length;
    },
    pastEnrolledCourseCount(state): number {
      return state.pastEnrolledCourses.length;
    },
  },
  actions: {
    async fetchCourses() {
      this.setLoading(true);
      try {
        this.courses = await listCourses();
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchEnrolledCourses() {
      this.setLoading(true);
      try {
        this.enrolledCourses = await getEnrolledCourses();
      } catch (error) {
        console.error("Failed to fetch enrolled courses:", error);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchPastEnrolledCourses() {
      this.setLoading(true);
      try {
        this.pastEnrolledCourses = await getPastEnrolledCourses();
      } catch (error) {
        console.error("Failed to fetch past enrolled courses:", error);
      } finally {
        this.setLoading(false);
      }
    },

    async updateCourseParticipants(
      courseId: string | number,
      participants: CourseParticipant[],
    ) {
      this.setLoading(true);
      try {
        const updatedCourse = await updateCourseParticipants(
          courseId,
          participants,
        );

        // Update the course in the appropriate array
        const updateCourseInArray = (courseArray: Course[]) => {
          const index = courseArray.findIndex(
            (course) => course.id === updatedCourse.id,
          );
          if (index !== -1) {
            courseArray[index] = updatedCourse;
          }
        };

        updateCourseInArray(this.enrolledCourses);
        updateCourseInArray(this.pastEnrolledCourses);

        return updatedCourse;
      } catch (error) {
        console.error("Failed to update course participants:", error);
        throw error;
      } finally {
        this.setLoading(false);
      }
    },

    clear() {
      this.courses = [];
      this.enrolledCourses = [];
      this.pastEnrolledCourses = [];
    },

    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
  },
});
