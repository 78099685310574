import { apiClient } from "@/services/api";
import { User } from "@/interfaces/auth.types";
import { LoginResponse } from "@/interfaces/responses.types";
import { RegisterUserData, ApiResponse } from "@/interfaces/auth.types";

/**
 * Login function
 *
 * @param {string} email
 * @param {string} password
 * @returns {Promise<LoginResponse>} - A promise that resolves to the LoginResponse
 */
const login = (email: string, password: string): Promise<ApiResponse> => {
  return apiClient
    .post<ApiResponse>("/api/auth/login", { email, password })
    .then((response) => response.data);
};

const forgotPassword = (email: string): Promise<void> => {
  return apiClient.post("/api/auth/forgot-password", { email });
};

/**
 * Register function
 *
 * @param {Object} userData - User registration data
 * @param {string} userData.accountType - 'personal' or 'business'
 * @param {string} userData.email
 * @param {string} userData.firstname
 * @param {string} userData.lastname
 * @param {string} userData.password
 * @param {string} [userData.orgNumber] - Required for business accounts
 * @param {string} [userData.address] - For business accounts
 * @param {string} [userData.zipcode] - For business accounts
 * @param {string} [userData.orgPhone] - For business accounts
 * @param {string} [userData.orgEmail] - For business accounts
 * @returns {Promise<User>} - A promise that resolves to the registered User
 */
const register = (userData: RegisterUserData): Promise<ApiResponse> => {
  return apiClient
    .post<ApiResponse>("/api/auth/register", userData)
    .then((response) => response.data);
};

const resetPassword = (
  token: string,
  email: string,
  password: string,
  password_confirmation: string,
): Promise<any> => {
  return apiClient.post("/api/auth/reset-password", {
    token,
    email,
    password,
    password_confirmation,
  });
};
/**
 * Fetches current user data
 *
 * @return {Promise<User>} A promise that resolves with the fetched user data.
 */
const fetchUser = (): Promise<ApiResponse> => {
  return apiClient
    .get<ApiResponse>("/api/user")
    .then((response) => response.data);
};

/**
 * Start impersonating a user
 *
 * @param {string} userId - The ID of the user to impersonate
 * @returns {Promise<ApiResponse>} - A promise that resolves with the impersonation token
 */
const impersonateUser = (userId: string): Promise<ApiResponse> => {
  return apiClient
    .post<ApiResponse>("/api/impersonate", { user_id: userId })
    .then((response) => response.data);
};

/**
 * Stop impersonating and revert to original user
 *
 * @returns {Promise<ApiResponse>} - A promise that resolves when impersonation is stopped
 */
const stopImpersonating = (): Promise<ApiResponse> => {
  return apiClient
    .post<ApiResponse>("/api/stop-impersonating")
    .then((response) => response.data);
};

const deleteUser = (): Promise<void> => {
  return apiClient.delete("/user");
};

export {
  login,
  fetchUser,
  forgotPassword,
  resetPassword,
  register,
  impersonateUser,
  stopImpersonating,
};
