import { apiClient } from "@/services/api";
import { Course, CourseParticipant } from "@/interfaces/courses.types";

const listCourses = (): Promise<Course[]> => {
  return apiClient
    .get<Course[]>("courses", {})
    .then((response) => response.data);
};

/**
 * Fetches the courses from the API.
 *
 * @function getCourses
 * @returns {Promise} A promise that resolves to an array of courses.
 */
const getCourses = (): Promise<Course[]> => {
  return apiClient
    .get<{ data: Course[] }>("api/courses")
    .then((response) => response.data.data);
};

/**
 * Fetches the enrolled courses for the current user.
 *
 * @function getEnrolledCourses
 * @returns {Promise} A promise that resolves to an array of enrolled courses with participants.
 */
const getEnrolledCourses = (): Promise<Course[]> => {
  return apiClient
    .get<Course[]>("api/orders/courses")
    .then((response) => response.data);
};

/**
 * Fetches the past enrolled courses for the current user.
 *
 * @function getPastEnrolledCourses
 * @returns {Promise} A promise that resolves to an array of past enrolled courses with participants.
 */
const getPastEnrolledCourses = (): Promise<Course[]> => {
  return apiClient
    .get<Course[]>("api/orders/courses/past")
    .then((response) => response.data);
};

/**
 * Updates participants for a specific course.
 *
 * @function updateCourseParticipants
 * @param {string|number} courseId - The ID of the course
 * @param {CourseParticipant[]} participants - Array of participant objects with name, email, phone, company, position
 * @returns {Promise} A promise that resolves to the updated course data.
 */
const updateCourseParticipants = (
  courseId: string | number,
  participants: CourseParticipant[],
): Promise<Course> => {
  return apiClient
    .post<Course>(`api/orders/courses/${courseId}/participants`, {
      participants,
    })
    .then((response) => response.data);
};

export {
  listCourses,
  getCourses,
  getEnrolledCourses,
  getPastEnrolledCourses,
  updateCourseParticipants,
};
