import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/store/auth";

import { defineComponent } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      template: "default",
      public: false,
      roles: ["admin"],
      permissions: ["read:home"],
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/views/dashboard/DashboardView.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/RegisterView.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPasswordView.vue"
      ),
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPasswordView.vue"
      ),
  },
  {
    path: "/orders",
    name: "orders",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/invoices/OrdersView.vue"
      ),
  },
  {
    path: "/invoices",
    name: "invoices",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/invoices/InvoicesView.vue"
      ),
  },
  {
    path: "/branches",
    name: "branches",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/branches/BranchesView.vue"
      ),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "invoices" */ "@/views/news/NewsView.vue"),
  },

  {
    path: "/webshop/:slug?",
    name: "webshop",
    meta: {
      template: "auth",
      public: true,
    },
    component: (): Promise<any> =>
      import(
        /* webpackChunkName: "accounts-new" */ "@/views/shop/ShopView.vue"
      ),
  },
  {
    path: "/webshop/:id",
    name: "products.view",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-edit" */ "../views/shop/SingleProductView.vue"
      ),
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "pod" */ "@/views/subscriptions/SubscriptionsView.vue"
      ),
  },
  {
    path: "/subscriptions/manage/:uuid",
    name: "subscriptions.manage",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "pod" */ "@/views/subscriptions/SubscriptionView.vue"
      ),
  },
  {
    path: "/courses",
    name: "courses",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/views/courses/CoursesView.vue"
      ),
  },
  {
    path: "/courses/orders",
    name: "courses.orders",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "course-orders" */ "@/views/courses/OrdersView.vue"
      ),
  },
  {
    path: "/courses/orders/:id/edit",
    name: "courses.edit-participants",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "course-edit-participants" */ "@/views/courses/EditParticipantsView.vue"
      ),
  },
  {
    path: "/courses/:id",
    name: "courses.view",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/views/courses/SingleCourseView.vue"
      ),
  },
  {
    path: "/cart",
    name: "cart",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/cart/CartView.vue"),
  },
  {
    path: "/cart/checkout",
    name: "checkout",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/cart/CheckoutView.vue"),
  },
  {
    path: "/organizations",
    name: "organizations.view",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/views/organizations/OrganizationsView.vue"
      ),
  },
  {
    path: "/organization/:id/edit",
    name: "organization.edit",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "organization" */ "@/views/branches/BranchCreateUpdateView.vue"
      ),
  },
  {
    path: "/organization/:id",
    name: "organization.view",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/views/organizations/OrganizationView.vue"
      ),
  },

  {
    path: "/my-account",
    name: "account",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/accounts/MyAccountView.vue"
      ),
  },
  {
    path: "/accounts",
    name: "accounts",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "../views/accounts/AccountsView.vue"
      ),
  },
  {
    path: "/accounts/new",
    name: "accounts.create",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-new" */ "../views/accounts/AccountsCreateUpdateView.vue"
      ),
  },
  {
    path: "/accounts/edit/:id",
    name: "accounts.edit",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-edit" */ "../views/accounts/AccountsCreateUpdateView.vue"
      ),
  },
  {
    path: "/my-account",
    name: "accounts.own",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-my" */ "../views/accounts/MyAccountView.vue"
      ),
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      defineComponent({
        template: "<div></div>",
      }),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      const authStore = useAuthStore();
      console.log("Logging out");
      authStore.logout();
      next({ name: "login" });
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve({
            left: savedPosition.left,
            top: savedPosition.top,
            behavior: "smooth",
          });
        } else {
          resolve({
            left: 0,
            top: 0,
            behavior: "smooth",
          });
        }
      }, 0);
    });
  },
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const auth = useAuthStore();

    if (!to.meta.public && !auth.isAuthenticated) {
      return next({ path: "/login" });
    }
    next();
  },
);

export default router;
